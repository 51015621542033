import CouponService from './CouponService'
import dayjs, { Dayjs } from 'dayjs'

class Coupon {
  couponId: string
  title: string
  description: string
  imageUrl: string
  expireDate: Dayjs
  couponImageUrl: string
  exchangedPoint: number
  exchangedAt: Dayjs
  couponService: CouponService

  constructor(
    couponId: string,
    title: string,
    description: string,
    imageUrl: string,
    expireDate: string,
    couponImageUrl: string,
    exchangedPoint: number,
    exchangedAt: string,
    couponService: CouponService
  ) {
    this.couponId = couponId
    this.title = title
    this.description = description
    this.imageUrl = imageUrl
    this.expireDate = dayjs(expireDate)
    this.couponImageUrl = couponImageUrl
    this.exchangedPoint = exchangedPoint
    this.exchangedAt = dayjs(exchangedAt)
    this.couponService = couponService
  }
}

export default Coupon
