import React, { FC } from 'react'
import WhiteModalContent from './white_modal_content'

type Props = {
  children: React.ReactNode
  title: string
  close: () => void
  additionalClass?: string
}

const TitleModalContent: FC<Props> = (props) => {
  return (
    <WhiteModalContent additionalClass={props.additionalClass}>
      <div className="relative bg-main-blue">
        <p className="py-4 text-white text-center text-lg sm:text-2xl font-bold">
          {props.title}
        </p>
        <button
          onClick={props.close}
          className="absolute top-0 right-0 mt-3 sm:mt-4 mr-4 text-white text-3xl font-bold"
        >
          ×
        </button>
      </div>
      <div className="max-h-[calc(85vh-100px)] overflow-y-auto">
        {props.children}
      </div>
    </WhiteModalContent>
  )
}

export default TitleModalContent
