import React, { FC } from 'react'
import { MaterialCert } from '../../../clients/api_client'
import OpenTitleCard from '../../../components/card/open_title_card'
import { Tooltip } from 'react-tooltip'

type Props = {
  materialCert: MaterialCert
}

const MaterialCertCard: FC<Props> = (props) => {
  return (
    <OpenTitleCard title="認証・評価の取得" defaultOpen={true}>
      <div>
        <div className="flex flex-wrap items-start py-6">
          {props.materialCert.certPosts.map((certPost, cpIndex) => (
            <div
              key={`cert-post-${cpIndex}`}
              className="mx-4 text-center max-w-40"
            >
              <img
                src={certPost.imageUrl}
                className="h-24 mx-auto mb-2"
                data-tooltip-id={`cert-tooltip-${cpIndex}`}
                data-tooltip-place="top"
              />
              <p className="font-bold mb-2">{certPost.name}</p>
              <div className="bg-main-blue mx-auto py-1 px-6 text-sm text-white rounded-full w-fit">
                {certPost.label}
              </div>
              <Tooltip
                clickable={true}
                id={`cert-tooltip-${cpIndex}`}
                className="max-w-52 sm:max-w-80 md:max-w-lg whitespace-break-spaces text-left !opacity-100 !bg-white !text-black shadow-lg"
              >
                {certPost.description}
              </Tooltip>
            </div>
          ))}
        </div>
        {(props.materialCert.sourceSiteName !== null ||
          props.materialCert.sourceSiteUrl !== null) && (
          <div className="w-full py-6 border-t border-main-lightGray text-main-lightGray text-sm text-right">
            {props.materialCert.sourceSiteName !== null && (
              <p className="leading-normal">
                引用元：{props.materialCert.sourceSiteName}
              </p>
            )}
            {props.materialCert.sourceSiteUrl !== null && (
              <a
                href={props.materialCert.sourceSiteUrl}
                target="_blank"
                rel="noreferrer"
                className="leading-normal underline"
              >
                {props.materialCert.sourceSiteUrl}
              </a>
            )}
          </div>
        )}
      </div>
    </OpenTitleCard>
  )
}

export default MaterialCertCard
