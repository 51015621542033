import React, { FC } from 'react'
import { useToggle } from 'react-use'
import { ArrowIcon } from '../../images/icons/arrow_icon'

type Props = {
  title: string
  children: React.ReactNode
  defaultOpen?: boolean
}

const OpenTitleCard: FC<Props> = (props) => {
  const [open, setOpen] = useToggle(
    props.defaultOpen === undefined ? false : props.defaultOpen
  )
  return (
    <div className="bg-white rounded-xl">
      <div
        className="flex justify-between items-center px-6 py-4 cursor-pointer"
        onClick={setOpen}
      >
        <span className="font-bold text-xl">{props.title}</span>
        <ArrowIcon
          className={`transition-transform duration-300 ${
            open ? 'rotate-180' : 'rotate-0'
          }`}
        />
      </div>
      {open && (
        <div className="px-6 py-2 border-t-2 border-main-gray">
          {props.children}
        </div>
      )}
    </div>
  )
}

export default OpenTitleCard
