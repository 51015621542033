import { Api } from '../clients/api_client'
import { ServerityType } from '../providers/snack_bar_provider'

// APIクライアントを作成する関数。リクエスト時に使用する共通の設定を含む。
export const getAppClient = (): Api<unknown> => {
  // ローカルストレージからアクセストークンを取得
  const token = window.localStorage.getItem('ACCESS-TOKEN')

  // APIクライアントのインスタンスを生成し、共通のベースURLとヘッダーを設定
  return new Api({
    baseUrl: process.env.REACT_APP_API_BASE_URL, // 環境変数からAPIのベースURLを取得
    baseApiParams: {
      headers: {
        Accept: 'application/json', // APIのレスポンスフォーマットをJSONに指定
        'Content-Type': 'application/json', // リクエストボディのフォーマットをJSONに指定
        Authorization: `Bearer ${token}`, // 認証に必要なBearerトークンをヘッダーに追加
      },
    },
  })
}

// APIのエラーレスポンスの型定義。レスポンスの中に含まれるエラー情報を格納。
export interface ApiErrorResponse extends Response {
  error: {
    errors: {
      [key: string]: string[] // エラーメッセージがフィールドごとに複数含まれる可能性
    }
    message: string | null // エラーメッセージ（オプション）
  }
}
// TODO：showSnackBarの1,2を消す
// APIエラーハンドリング用の関数。エラー内容に応じて適切なエラーメッセージを表示する。
export const handleError = (
  errorResponse: ApiErrorResponse, // エラーレスポンスを受け取る
  showSnackBar: (messages: string[], type: ServerityType) => void // スナックバーを表示する関数
) => {
  // サーバー側のエラー（ステータスコード500）の場合
  if (errorResponse.status === 500) {
    showSnackBar(
      [
        'エラーが発生しました。お手数ですがお問い合わせよりお問い合わせください。',
      ],
      'error' // エラータイプのメッセージをスナックバーに表示
    )
    // クライアントエラー（ステータスコード400）の場合
  } else if (errorResponse.status === 400) {
    // エラーリストが含まれている場合、そのエラーメッセージを表示
    if (Object.keys(errorResponse.error.errors).length > 0) {
      showSnackBar(Object.values(errorResponse.error.errors).flat(), 'error')
      // 単一のエラーメッセージがある場合、そのメッセージを表示
    } else if (errorResponse.error.message) {
      showSnackBar([errorResponse.error.message], 'error')
      // それ以外のエラー
    } else {
      showSnackBar(
        [
          'エラーが発生しました。お手数ですがお問い合わせよりお問い合わせください。',
        ],
        'error'
      )
    }
    // 500や400以外のエラー（通信エラーなど）に対して
  } else {
    console.error(errorResponse)
    showSnackBar(
      ['エラーが発生しました。通信環境をご確認の上、再度お試しください。'],
      'error'
    )
  }
}
