import React, { FC, useEffect, useState } from 'react'
import { MaterialGhg, MaterialGhgPost } from '../../../clients/api_client'
import OpenTitleCard from '../../../components/card/open_title_card'

type Props = {
  materialGhg: MaterialGhg
}

const MaterialGhgCard: FC<Props> = (props) => {
  const [years, setYears] = useState<number[]>([])
  const [selectPost, setSelectPost] = useState<MaterialGhgPost | null>(null)

  useEffect(() => {
    const newYears = props.materialGhg.ghgPosts.map((ghgPost) => ghgPost.year)
    setYears(newYears)

    if (props.materialGhg.ghgPosts.length > 0) {
      setSelectPost(props.materialGhg.ghgPosts[0])
    }
  }, [props.materialGhg])

  if (selectPost === null || years.length === 0) {
    return <></>
  }

  return (
    <OpenTitleCard title="GHG（温暖化ガス）排出量" defaultOpen={true}>
      <div>
        <div className="py-6">
          <div className="mb-6 whitespace-break-spaces">
            {selectPost.content}
          </div>
          <img src={selectPost.imageUrl} className="w-full shadow-lg mb-6" />
          <div className="flex items-center">
            {years.map((year) => (
              <div
                onClick={() => {
                  const newPost = props.materialGhg.ghgPosts.find(
                    (ghgPost) => ghgPost.year === year
                  )
                  if (newPost !== undefined) {
                    setSelectPost(newPost)
                  }
                }}
                className={`mr-4 underline ${
                  year === selectPost.year
                    ? 'text-main-blue'
                    : 'text-main-lightGray cursor-pointer'
                }`}
                key={`year-${year}`}
              >
                {year}
              </div>
            ))}
          </div>
        </div>
        {(selectPost.sourceSiteName !== null ||
          selectPost.sourceSiteUrl !== null) && (
          <div className="w-full py-6 border-t border-main-lightGray text-main-lightGray text-sm text-right">
            {selectPost.sourceSiteName !== null && (
              <p className="leading-normal">
                引用元：{selectPost.sourceSiteName}
              </p>
            )}
            {selectPost.sourceSiteUrl !== null && (
              <a
                href={selectPost.sourceSiteUrl}
                target="_blank"
                rel="noreferrer"
                className="leading-normal underline"
              >
                {selectPost.sourceSiteUrl}
              </a>
            )}
          </div>
        )}
      </div>
    </OpenTitleCard>
  )
}

export default MaterialGhgCard
