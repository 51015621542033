import React, { FC } from 'react'
import TitleModalContent from '../../../../components/modal/title_modal_content'
import FlatButton from '../../../../components/buttons/flat_button'
import { useNavigate } from 'react-router-dom'

type Props = {
  close: () => void
}

const CouponExchangeCompleteModal: FC<Props> = (props) => {
  const navigate = useNavigate()
  return (
    <TitleModalContent
      close={props.close}
      title="チケット交換画面"
      additionalClass="w-[700px]"
    >
      <div>
        <div className="py-6 px-4 sm:px-8">
          <h3 className="font-bold text-lg mb-2">交換が完了しました！</h3>
          <div className="mb-8">
            <p>チケット交換ありがとうございました。</p>
            <p>交換したチケットは下記のページからご確認いただけます。</p>
          </div>
          <FlatButton
            onClick={() => {
              navigate('/mypage/ticket')
            }}
          >
            交換チケット一覧
          </FlatButton>
        </div>
      </div>
    </TitleModalContent>
  )
}

export default CouponExchangeCompleteModal
