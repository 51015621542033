import React, { FC, useEffect, useState } from 'react'
import Coupon from '../../../../model/Coupon'
import TitleModalContent from '../../../../components/modal/title_modal_content'
import { HoldPointIcon } from '../../../../images/icons/hold_point_icon'
import FlatButton from '../../../../components/buttons/flat_button'
import LoadingSpinner from '../../../../components/loading/loading_spinner'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../../utils/network_util'
import { useSnackBar } from '../../../../providers/snack_bar_provider'
import { useLoading } from '../../../../providers/loading_provider'

type Props = {
  coupon: Coupon
  complete: () => void
  close: () => void
  back: () => void
}

const CouponExchangeConfirmModal: FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const apiClient = getAppClient()
  const { showSnackBar } = useSnackBar()
  const [coupon, setCoupon] = useState<Coupon | null>(null)
  const [currentPoint, setCurrentPoint] = useState<number | null>(null)
  const [remainPoint, setRemainPoint] = useState<number | null>(null)
  const { showLoading, hideLoading } = useLoading()

  useEffect(() => {
    if (coupon === null || props.coupon.id !== coupon.id) {
      checkExchange()
    }
  }, [props.coupon])

  const checkExchange = async () => {
    setLoading(true)
    await apiClient.coupons
      .exchangeConfirmDetail(props.coupon.id)
      .then((res) => {
        setErrorMessage(res.data.errorMessage)
        setCurrentPoint(res.data.currentPoint)
        setRemainPoint(res.data.remainPoint)
        setCoupon(res.data.coupon)
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
        return null
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const exchange = async () => {
    showLoading()
    await apiClient.coupons
      .exchangeCreate(props.coupon.id)
      .then(() => {
        props.complete()
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
      })
      .finally(() => {
        hideLoading()
      })
  }

  return (
    <TitleModalContent
      close={props.close}
      title="チケット交換画面"
      additionalClass="w-[700px]"
    >
      <div>
        <div className="py-6 px-4 sm:px-8">
          <h3 className="font-bold text-lg sm:text-xl mb-4">
            チケット交換を確定しますか？
          </h3>
          {loading ? (
            <LoadingSpinner className="mx-auto my-12" />
          ) : (
            <>
              <div>
                <div className="mb-8">
                  <h5 className="text-sm mb-1">交換するチケットのポイント数</h5>
                  <div className="flex items-center">
                    <HoldPointIcon
                      size="md"
                      additionalClassName="relative top-[2px]"
                    />
                    <span className="font-bold text-3xl pl-1 text-main-blue">
                      {coupon?.point.toLocaleString()}
                    </span>
                  </div>
                </div>
                <div className="mb-2">
                  <h5 className="text-sm mb-1">
                    交換後の、あなたの残りポイント
                  </h5>
                  <div className="flex items-center">
                    <div className="flex items-center">
                      <HoldPointIcon
                        size="sm"
                        color="gray"
                        additionalClassName="relative"
                      />
                      <span className="font-bold text-main-lightGray text-lg ml-1">
                        {currentPoint?.toLocaleString()}
                      </span>
                    </div>
                    <div className="flex items-center px-4">
                      <span className="-rotate-90 text-main-blue text-xs opacity-30">
                        ▼
                      </span>
                      <span className="-rotate-90 text-main-blue text-xs opacity-70">
                        ▼
                      </span>
                      <span className="-rotate-90 text-main-blue text-xs">
                        ▼
                      </span>
                    </div>
                    <div className="flex items-center">
                      <HoldPointIcon size="sm" additionalClassName="relative" />
                      <span className="font-bold text-lg ml-1 text-main-blue">
                        {remainPoint ? remainPoint.toLocaleString() : '-'}
                      </span>
                    </div>
                  </div>
                </div>
                {errorMessage && (
                  <p className="text-main-red pt-8">{errorMessage}</p>
                )}
              </div>
            </>
          )}
        </div>
        <div className="w-full bg-main-gray py-4 px-6 flex justify-center sm:justify-end">
          <FlatButton
            className="!mx-0"
            disabled={loading || errorMessage !== null}
            onClick={exchange}
          >
            確定する
          </FlatButton>
        </div>
      </div>
    </TitleModalContent>
  )
}

export default CouponExchangeConfirmModal
