import { useCallback, useState } from 'react'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import { useSnackBar } from '../../../providers/snack_bar_provider'
import UserCoupon from '../../../model/UserCoupon'

type Handler = {
  load: (sortOrder?: UserCouponSortOrder, cursor?: number) => Promise<void>
}

export type UserCouponSortOrder = 'new' | 'old'

// 購入済みのクーポンを取得する
const useUserCoupons = (
  getLimit = 20
): [Array<UserCoupon>, number, number, boolean, Handler] => {
  const apiClient = getAppClient()

  const [isLoading, setIsLoading] = useState(false)
  const [totalSize, setTotalSize] = useState(0)
  const [cursor, setCursor] = useState(0)
  const [userCoupons, setUserCoupons] = useState<Array<UserCoupon>>([])
  const { showSnackBar } = useSnackBar()

  const load = useCallback(
    async (sortOrder: UserCouponSortOrder = 'new', cursor = 0) => {
      if (cursor < 0 || (totalSize && cursor >= totalSize)) {
        return
      }

      setIsLoading(true)
      await apiClient.coupons
        .exchangedList({
          cursor: cursor,
          limit: getLimit,
          search_order: sortOrder,
        })
        .then((res) => {
          const result = res.data
          if (totalSize !== result.total) {
            setTotalSize(result.total)
          }
          if (result.data) {
            setCursor(cursor)
            setUserCoupons(
              result.data.map(
                (uc) =>
                  new UserCoupon(
                    uc.couponId,
                    uc.title,
                    uc.description,
                    uc.imageUrl,
                    uc.expireDate,
                    uc.couponImageUrl,
                    uc.exchangedPoint,
                    uc.exchangedAt,
                    uc.couponService
                  )
              )
            )
          }
        })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)
          return
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [totalSize, cursor, userCoupons]
  )

  return [userCoupons, totalSize, cursor, isLoading, { load: load }]
}

export default useUserCoupons
