import React, { FC } from 'react'
import TitleModalContent from '../../../../components/modal/title_modal_content'

import UserCoupon from '../../../../model/UserCoupon'
import { HoldPointIcon } from '../../../../images/icons/hold_point_icon'
// import LoadingSpinner from '../../../../components/loading/loading_spinner'

type Props = {
  close: () => void
  userCoupon: UserCoupon
}

const UserCouponDetailModal: FC<Props> = (props) => {
  // QRコードを文字列から生成する場合
  // const [qrcodeURL, setQrcodeURL] = React.useState<string | null>()
  // const generateQRCodeAsImage = async (text: string): Promise<void> => {
  //   try {
  //     const qrCodeDataUrl = await QRCode.toDataURL(text)
  //     setQrcodeURL(qrCodeDataUrl)
  //   } catch (error) {
  //     console.error('Failed to generate QR Code image:', error)
  //   }
  // }
  // useEffect(() => {
  //   generateQRCodeAsImage(props.userCoupon.couponImageUrl)
  // }, [props.userCoupon.couponImageUrl])

  return (
    <TitleModalContent
      close={props.close}
      title="チケット詳細"
      additionalClass="w-[700px]"
    >
      <div>
        <div className="py-6 px-4 sm:px-8">
          <h3 className="font-bold text-lg mb-2">{props.userCoupon.title}</h3>
          <div className="sm:flex">
            {/* 左 */}
            <div className="w-full sm:w-[120px]">
              <img
                src={props.userCoupon.imageUrl}
                className="w-full block mb-2 max-w-[300px] mx-auto "
              />
              <div className="flex items-center">
                <div className="relative top-[2px]">
                  <HoldPointIcon />
                </div>
                <span className="text-main-blue text-xl font-bold px-1">
                  {props.userCoupon.exchangedPoint.toLocaleString()}
                </span>
              </div>
            </div>
            {/* 右 */}
            <div className="w-full sm:w-[calc(100%-120px)] sm:pl-4">
              <div className="mb-2">{props.userCoupon.description}</div>
              <p className="text-sm">
                有効期限：{props.userCoupon.expireDate.format('YYYY年M月D日')}
                まで
              </p>
            </div>
          </div>
          <hr className="mt-8 pb-6 border-main-black" />
          <p className="font-bold mb-2">下記で利用可能なクーポンです。</p>
          <div className="flex mb-4">
            <img
              src={props.userCoupon.couponService.imageUrl}
              className="w-[80px] h-auto object-contain"
            />
            <div className="mb-2 pl-4">
              <p className="font-bold mb-1">
                {props.userCoupon.couponService.title}
              </p>
              <p className="text-sm">
                {props.userCoupon.couponService.description}
              </p>
            </div>
          </div>
          {props.userCoupon.couponService.imageUrl && (
            <a
              href={props.userCoupon.couponService.imageUrl}
              target="_blank"
              rel="noreferrer"
              className="text-main-blue text-sm break-words"
            >
              {props.userCoupon.couponService.imageUrl}
            </a>
          )}
        </div>
        <div className="py-6 px-4 sm:px-8 bg-main-gray">
          <h5 className="font-bold mb-4">クーポンコード</h5>
          <img
            src={props.userCoupon.couponImageUrl}
            className="block mx-auto mb-8"
          />
        </div>
      </div>
    </TitleModalContent>
  )
}

export default UserCouponDetailModal
