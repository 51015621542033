import React, { FC } from 'react'
import Coupon from '../../../../model/Coupon'
import TitleModalContent from '../../../../components/modal/title_modal_content'
import { HoldPointIcon } from '../../../../images/icons/hold_point_icon'
import FlatButton from '../../../../components/buttons/flat_button'

type Props = {
  coupon: Coupon
  confirm: () => void
  close: () => void
}

const CouponDetailModal: FC<Props> = (props) => {
  return (
    <TitleModalContent
      close={props.close}
      title="チケット交換画面"
      additionalClass="w-[700px]"
    >
      <div>
        <div className="py-6 px-4 sm:px-8">
          <h3 className="font-bold text-lg mb-2">{props.coupon.title}</h3>
          <div className="sm:flex">
            {/* 左 */}
            <div className="w-full sm:w-[120px]">
              <img
                src={props.coupon.imageUrl}
                className="w-full max-w-[300px] mx-auto  block mb-2"
              />
              <div className="flex items-center">
                <div className="relative top-[2px]">
                  <HoldPointIcon />
                </div>
                <span className="text-main-blue text-xl font-bold px-1">
                  {props.coupon.point.toLocaleString()}
                </span>
              </div>
            </div>
            {/* 右 */}
            <div className="w-full sm:w-[calc(100%-120px)] sm:pl-4">
              <div className="mb-2">{props.coupon.description}</div>
              <p className="text-sm">有効期限：{props.coupon.expireDate}まで</p>
            </div>
          </div>
          <hr className="mt-8 pb-6 border-main-black" />
          <p className="font-bold mb-2">下記で利用可能なクーポンです。</p>
          <div className="flex mb-4">
            <img
              src={props.coupon.couponService.imageUrl}
              className="w-[80px] h-auto object-contain"
            />
            <div className="mb-2 pl-4">
              <p className="font-bold mb-1">
                {props.coupon.couponService.title}
              </p>
              <p className="text-sm">
                {props.coupon.couponService.description}
              </p>
            </div>
          </div>
          {props.coupon.couponService.imageUrl && (
            <a
              href={props.coupon.couponService.imageUrl}
              target="_blank"
              rel="noreferrer"
              className="text-main-blue text-sm break-words"
            >
              {props.coupon.couponService.imageUrl}
            </a>
          )}
        </div>
        <div className="w-full bg-main-gray py-4 px-6 flex justify-center sm:justify-end">
          <FlatButton className="!mx-0" onClick={props.confirm}>
            チケットを交換する
          </FlatButton>
        </div>
      </div>
    </TitleModalContent>
  )
}

export default CouponDetailModal
