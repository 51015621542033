import React, { useEffect, useState } from 'react'
import MyPageTemplate from './components/templates/mypage_template'
import useUser from './hooks/use_user'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import PagiNationCursor from '../../components/pagination/pagination_cursor'
import LoadingSpinner from '../../components/loading/loading_spinner'
import { useModal } from 'react-hooks-use-modal'
import useUserCoupons, { UserCouponSortOrder } from './hooks/use_user_coupons'
import UserCoupon from '../../model/UserCoupon'
import FlatButton from '../../components/buttons/flat_button'
import UserCouponDetailModal from './components/modal/user_coupon_detail_modal'

const MyPageTicketPage = () => {
  const PAGE_SIZE = 20

  const isSp = useSpBreakPoint()
  const [sortOrder, setSortOrder] = useState<UserCouponSortOrder>('new')
  const [userCoupons, totalSize, currentCursor, couponLoading, couponHandler] =
    useUserCoupons(PAGE_SIZE)
  const [user, userLoading] = useUser()
  const [DetailModal, openDetailModal, closeDetailModal] = useModal('root', {
    preventScroll: false,
    focusTrapOptions: {
      clickOutsideDeactivates: true,
    },
  })
  const [selectUserCoupon, setSelectUserCoupon] = useState<UserCoupon | null>(
    null
  )

  useEffect(() => {
    couponHandler.load(sortOrder)
  }, [sortOrder])

  const IndexSectionRef = React.createRef<HTMLDivElement>()
  const scrollToIndexSection = () => {
    if (IndexSectionRef.current) {
      const elementPosition =
        IndexSectionRef.current.getBoundingClientRect().top + window.scrollY
      const offsetPosition = elementPosition - 100
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }

  const sortSelectElems = (
    <>
      <div className="flex items-center">
        {[
          { title: '新着順', value: 'new' },
          { title: '古い順', value: 'old' },
        ].map((item) => {
          return (
            <button
              key={item.value}
              className={`${
                item.value === sortOrder
                  ? 'bg-main-blue text-white'
                  : 'bg-white text-main-blue'
              } py-1 px-4 rounded-md mr-4`}
              onClick={() => {
                setSortOrder(item.value as UserCouponSortOrder)
              }}
            >
              {item.title}
            </button>
          )
        })}
      </div>
    </>
  )

  const couponElems = (
    <>
      <div className="rounded-xl bg-white">
        {userCoupons?.map((userCoupon, index) => {
          return (
            <div key={`uc-${String(index)}`} className="py-6 px-6">
              <div className="sm:flex items-start">
                <img
                  src={userCoupon.imageUrl}
                  className="w-full max-w-[300px] mx-auto sm:w-[160px] object-contain mb-4 sm:mb-0"
                />
                <div className="w-full sm:w-[calc(100%-280px)] sm:px-4 mb-4 sm:mb-0">
                  <h4 className="font-bold text-lg mb-2">{userCoupon.title}</h4>
                  <div className="mb-2">{userCoupon.description}</div>
                  <p className="text-sm mb-1">
                    有効期限：{userCoupon.expireDate.format('YYYY年M月D日')}まで
                  </p>
                  <p className="text-sm">
                    交換日：
                    {userCoupon.exchangedAt.format('YYYY年M月D日')}
                  </p>
                </div>
                <div className="w-full sm:w-[120px] flex items-center">
                  <FlatButton
                    size="sm"
                    className="text-sm max-sm:max-w-none"
                    onClick={() => {
                      setSelectUserCoupon(userCoupon)
                      openDetailModal()
                    }}
                  >
                    クーポンを
                    <br className="hidden sm:block" />
                    確認する
                  </FlatButton>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      {userCoupons.length === 0 && !couponLoading && (
        <div className="text-center py-4 text-gray-400">
          交換済のチケットはありません。
        </div>
      )}
    </>
  )

  const paginationCursor =
    userCoupons.length > 0 ? (
      <PagiNationCursor
        pageCount={Math.ceil(totalSize / PAGE_SIZE)}
        numRange={3}
        activePageNum={Math.floor(currentCursor / PAGE_SIZE) + 1}
        next={() => {
          scrollToIndexSection()
          couponHandler.load(sortOrder, currentCursor + PAGE_SIZE)
        }}
        prev={() => {
          scrollToIndexSection()
          couponHandler.load(sortOrder, currentCursor - PAGE_SIZE)
        }}
        jump={(pageNum: number) => {
          scrollToIndexSection()
          couponHandler.load(sortOrder, (pageNum - 1) * PAGE_SIZE)
        }}
      />
    ) : null

  const content = (
    <MyPageTemplate
      userLoading={userLoading}
      head="ポイント交換"
      user={user}
      isSp={isSp}
      subHead={
        couponLoading
          ? ''
          : `全${totalSize}件中 ${currentCursor + 1}〜${currentCursor + PAGE_SIZE > totalSize ? totalSize : currentCursor + PAGE_SIZE}件表示`
      }
    >
      <div ref={IndexSectionRef} />
      {couponLoading || userLoading ? (
        <LoadingSpinner className="mx-auto" />
      ) : (
        <>
          <div className="pb-8">{sortSelectElems}</div>
          <div className="pb-8">{couponElems}</div>
          <div className="pb-8">{paginationCursor}</div>
        </>
      )}
      {selectUserCoupon && (
        <>
          <DetailModal>
            <UserCouponDetailModal
              userCoupon={selectUserCoupon}
              close={closeDetailModal}
            />
          </DetailModal>
        </>
      )}
    </MyPageTemplate>
  )

  return content
}

export default MyPageTicketPage
