import React, { FC } from 'react'
import { MaterialActivity } from '../../../clients/api_client'
import OpenTitleCard from '../../../components/card/open_title_card'
import { useSdgsImage } from '../../../hooks/use_sdgs_image'

type Props = {
  materialActivity: MaterialActivity
}

const MaterialActivityCard: FC<Props> = (props) => {
  const [getSdgsImage] = useSdgsImage()

  return (
    <OpenTitleCard title="SDGsの具体的な取り組み" defaultOpen={true}>
      <div>
        {props.materialActivity.activityPosts.map((activityPost, apIndex) => (
          <div
            key={`activity-post-${apIndex}`}
            className={`
                ${apIndex === 0 ? '' : 'border-t'} border-main-lightGray w-full py-6
              `}
          >
            <div className="mb-6">
              <p className="font-bold text-lg mb-2">{activityPost.title}</p>
              <div className="flex flex-wrap">
                {activityPost.sdgsCategories.map((sdgsCategory, apsIndex) => (
                  <div
                    key={`activity-post-sdgs-category-${apsIndex}`}
                    className="flex items-center mr-4 mb-2"
                  >
                    <img
                      alt={sdgsCategory.value}
                      src={getSdgsImage(sdgsCategory.code)}
                      width={20}
                      height={20}
                    />
                    <p className="text-xs text-main-lightGray pl-1">
                      {sdgsCategory.value}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <div className="sm:flex items-start">
              {activityPost.imageUrl !== null && (
                <img
                  src={activityPost.imageUrl}
                  className="w-full sm:w-64 mr-4 mb-4 sm:mb-0"
                />
              )}
              <div className="whitespace-break-spaces">
                {activityPost.content}
              </div>
            </div>
          </div>
        ))}
      </div>
      {(props.materialActivity.sourceSiteName !== null ||
        props.materialActivity.sourceSiteUrl !== null) && (
        <div className="w-full py-6 border-t border-main-lightGray text-main-lightGray text-sm text-right">
          {props.materialActivity.sourceSiteName !== null && (
            <p className="leading-normal">
              引用元：{props.materialActivity.sourceSiteName}
            </p>
          )}
          {props.materialActivity.sourceSiteUrl !== null && (
            <a
              href={props.materialActivity.sourceSiteUrl}
              target="_blank"
              rel="noreferrer"
              className="leading-normal underline"
            >
              {props.materialActivity.sourceSiteUrl}
            </a>
          )}
        </div>
      )}
    </OpenTitleCard>
  )
}

export default MaterialActivityCard
