import React, { FC } from 'react'
import { MaterialTcfd } from '../../../clients/api_client'
import OpenTitleCard from '../../../components/card/open_title_card'

type Props = {
  materialTcfd: MaterialTcfd
}

const MaterialTcfdCard: FC<Props> = (props) => {
  return (
    <OpenTitleCard title="TCFD提言に基づく情報開示" defaultOpen={true}>
      <div>
        <div className="py-6">
          <div className="mb-6 whitespace-break-spaces">
            {props.materialTcfd.content}
          </div>
          <img src={props.materialTcfd.imageUrl} className="w-full shadow-lg" />
        </div>
        {(props.materialTcfd.sourceSiteName !== null ||
          props.materialTcfd.sourceSiteUrl !== null) && (
          <div className="w-full py-6 border-t border-main-lightGray text-main-lightGray text-sm text-right">
            {props.materialTcfd.sourceSiteName !== null && (
              <p className="leading-normal">
                引用元：{props.materialTcfd.sourceSiteName}
              </p>
            )}
            {props.materialTcfd.sourceSiteUrl !== null && (
              <a
                href={props.materialTcfd.sourceSiteUrl}
                target="_blank"
                rel="noreferrer"
                className="leading-normal underline"
              >
                {props.materialTcfd.sourceSiteUrl}
              </a>
            )}
          </div>
        )}
      </div>
    </OpenTitleCard>
  )
}

export default MaterialTcfdCard
