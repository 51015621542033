import React, { FC } from 'react'
import FlatButton from '../../../components/buttons/flat_button'
import TitleModalContent from '../title_modal_content'

type ReportCompleteModalProps = {
  close: () => void
  reportNo: string
}

const ReportCompleteModalContent: FC<ReportCompleteModalProps> = ({
  close,
  reportNo,
}) => {
  return (
    <TitleModalContent
      close={close}
      title="クチコミ報告フォーム"
      additionalClass="w-[700px]"
    >
      <div className="p-8">
        <p className="text-center font-bold mb-4">
          クチコミの報告が完了しました。
        </p>
        <div className="text-left text-sm">
          <p className="mb-4">報告No： {reportNo}</p>
          <p>ご報告いただき、誠にありがとうございます。</p>
          <p className="mb-8">
            お寄せいただいた内容は、私たちのコミュニティをより安全で信頼できる場にするために大変貴重です。迅速に対応を進めてまいります。
          </p>
          <p className="mb-8">
            またご報告いただいた内容に基づき、さらに削除申請やその他のお問合せをご希望の場合は、お問合せフォームより「報告No：{' '}
            {reportNo}
            」を記載し、お気軽にご連絡ください。専門のサポートチームが迅速に対応させていただきます。
          </p>
          <p className="mb-8">
            私たちは、あなたの声を大切にし、常にサービスの向上を図っております。
          </p>
          <p className="text-main-red font-bold">
            報告いただいたクチコミのお問い合わせの場合は「報告No： {reportNo}
            」をコピーやメモを行った上でお問合せください。
          </p>
        </div>
        <FlatButton className="!bg-main-blue mt-4" size="lg" onClick={close}>
          閉じる
        </FlatButton>
      </div>
    </TitleModalContent>
  )
}

export default ReportCompleteModalContent
