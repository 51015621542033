/*
 * ここの値はLaravel側と合わせる必要あり
 */

import {
  RankingPeriod,
  RankingEnterpriseStat,
  RankingKuchikomiStat,
  RankingUserStat,
  BookmarkStat,
} from '../clients/api_client'

export const ENTERPRISECODES = [
  { code: 'listed', value: '上場企業' },
  { code: 'unlisted', value: '未上場企業' },
  { code: 'local', value: '地方企業' },
  { code: 'foreign', value: '外資系企業' },
  { code: 'venture', value: 'ベンチャー企業' },
  { code: 'government', value: '官公庁・団体' },
]

export const SDGSCATEGORY = [
  { code: '1', value: '貧困をなくそう' },
  { code: '2', value: '飢餓をゼロに' },
  { code: '3', value: 'すべての人に健康と福祉を' },
  { code: '4', value: '質の高い教育をみんなに' },
  { code: '5', value: 'ジェンダー平等を実現しよう' },
  { code: '6', value: '安全な水とトイレを世界中に' },
  { code: '7', value: 'エネルギーをみんなに そしてクリーンに' },
  { code: '8', value: '働きがいも経済成長も' },
  { code: '9', value: '産業と技術革新の基盤をつくろう' },
  { code: '10', value: '人や国の不平等をなくそう' },
  { code: '11', value: '住み続けられるまちづくりを' },
  { code: '12', value: 'つくる責任 つかう責任' },
  { code: '13', value: '気候変動に具体的な対策を' },
  { code: '14', value: '海の豊かさを守ろう' },
  { code: '15', value: '陸の豊かさも守ろう' },
  { code: '16', value: '平和と公正をすべての人に' },
  { code: '17', value: 'パートナーシップで目標を達成しよう' },
]

export const OCCUPATIONCODE = [
  { code: 'employee', value: '会社員' },
  { code: 'public_officer', value: '公務員' },
  { code: 'self', value: '自営業' },
  { code: 'board_member', value: '会社役員' },
  { code: 'fleerance', value: '自由業' },
  { code: 'housewife', value: '専業主婦（夫）' },
  { code: 'student', value: '学生' },
  { code: 'part_time', value: 'パート・アルバイト' },
  { code: 'none', value: '無職' },
]

export const GENDERCODE = [
  { code: 'male', value: '男性' },
  { code: 'female', value: '女性' },
  { code: 'other', value: 'その他' },
]

export const PRFECTURES = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県',
]

export const INDUSTRY_CODE = [
  { code: 'fishery_agriculture', value: '水産・農林業' },
  { code: 'mining', value: '鉱業' },
  { code: 'construction', value: '建設業' },
  { code: 'manufacturing', value: '製造業' },
  { code: 'electricity_gas', value: '電気・ガス業・水道業' },
  { code: 'transportation', value: '倉庫・運輸関連業' },
  { code: 'information_communication', value: '情報通信' },
  { code: 'commerce', value: '商業（卸売業、小売業）' },
  { code: 'finance_insurance', value: '金融・保険業' },
  { code: 'real_estate', value: '不動産業' },
  { code: 'food_accommodation', value: '飲食店・宿泊業' },
  { code: 'medical_welfare', value: '医療・福祉' },
  { code: 'education', value: '教育・学習支援業' },
  { code: 'service', value: 'サービス業' },
  { code: 'government', value: '官公庁・地方自治体' },
  { code: 'organization', value: '財団法人・社団法人・宗教法人・団体' },
  { code: 'sports', value: 'スポーツ' },
  { code: 'country', value: '国' },
  { code: 'world', value: '世界' },
]

export const INDUSTRY_CHILD_CODE = [
  { code: 'agriculture', value: '農業' },
  { code: 'forestry', value: '林業' },
  { code: 'fisheries', value: '水産業' },
  { code: 'mining_and_quarrying', value: '鉱業・採石業・砂利採取業' },
  { code: 'construction', value: '建設' },
  { code: 'construction_consulting', value: '建設コンサルタント' },
  { code: 'plant_engineering', value: 'プラント' },
  { code: 'food_products', value: '食料品' },
  { code: 'textiles', value: '繊維製品' },
  { code: 'pulp_and_paper', value: 'パルプ・紙' },
  { code: 'chemicals', value: '化学' },
  { code: 'pharmaceuticals', value: '医薬品' },
  { code: 'rubber_products', value: 'ゴム製品' },
  { code: 'steel', value: '鉄鋼' },
  { code: 'non_ferrous_metals', value: '非鉄金属' },
  { code: 'metal_products', value: '金属製品' },
  { code: 'machinery', value: '機械' },
  { code: 'electrical_equipment', value: '電気機器' },
  { code: 'transportation_equipment', value: '輸送用機器' },
  { code: 'precision_instruments', value: '精密機器' },
  { code: 'glass_and_ceramic_products', value: 'ガラス・土石製品' },
  { code: 'petroleum_and_coal_products', value: '石油・石炭製品' },
  { code: 'other_products', value: 'その他製品' },
  { code: 'electricity', value: '電気業' },
  { code: 'gas', value: 'ガス業' },
  { code: 'heat_supply', value: '熱供給業' },
  { code: 'water_supply', value: '水道業' },
  { code: 'railway_services', value: '鉄道業' },
  { code: 'road_passenger_transport', value: '道路旅客運送業' },
  { code: 'road_freight_transport', value: '道路貨物運送業' },
  { code: 'water_transport', value: '水運業' },
  { code: 'air_transport', value: '航空運輸業' },
  { code: 'warehousing', value: '倉庫業' },
  {
    code: 'transportation_support_services',
    value: '運輸業',
  },
  { code: 'postal_services', value: '郵便業' },
  { code: 'information_services', value: '情報サービス業' },
  { code: 'telecommunications', value: '通信業' },
  { code: 'broadcasting', value: '放送業' },
  { code: 'gaming', value: 'ゲーム' },
  { code: 'system_integrators', value: 'SIer・ソフトウェア開発・システム運用' },
  { code: 'data_centers', value: 'データセンター' },
  { code: 'wholesale', value: '卸売業' },
  { code: 'department_stores', value: '百貨店・デパート・複合商業施設' },
  { code: 'supermarkets', value: 'スーパー・コンビニエンスストア' },
  { code: 'home_centers', value: 'ホームセンター' },
  { code: 'consumer_electronics', value: '家電' },
  { code: 'fashion_and_apparel', value: 'ファッション・服飾雑貨・繊維' },
  { code: 'automotive_and_transportation', value: '自動車・輸送機器' },
  { code: 'music_books_and_interior_goods', value: '音楽・書籍・インテリア' },
  { code: 'drugstores', value: 'ドラッグストア・医薬品・化粧品・調剤薬局' },
  { code: 'optical_goods', value: 'メガネ・コンタクト・医療機器' },
  { code: 'sporting_goods', value: 'スポーツ用品' },
  { code: 'energy', value: '電力・ガス・水道・エネルギー' },
  { code: 'consumer_cooperatives', value: '生活協同組合' },
  { code: 'electronics_and_semiconductors', value: '電機・電子・半導体' },
  { code: 'second_hand_and_reuse', value: '古物商・リユース' },
  { code: 'other_retail_and_distribution', value: 'その他流通・小売' },
  {
    code: 'investment_funds_and_related_services',
    value: '投資ファンド・投資関連',
  },
  { code: 'securities_and_futures', value: '証券・商品先物' },
  { code: 'banks', value: '銀行' },
  { code: 'credit_unions', value: '信用組合' },
  { code: 'credit_associations', value: '信用金庫' },
  { code: 'agricultural_finance', value: '農林水産金融' },
  { code: 'insurance', value: '保険' },
  { code: 'credit_and_financing', value: 'クレジット・信販' },
  { code: 'mergers_and_acquisitions', value: 'M&A' },
  { code: 'other_financial_services', value: 'その他金融・保険' },
  { code: 'real_estate_transactions', value: '不動産取引業' },
  { code: 'real_estate_leasing_and_management', value: '不動産賃貸業・管理業' },
  { code: 'goods_leasing', value: '物品賃貸業' },
  { code: 'accommodation_services', value: '宿泊業' },
  { code: 'restaurants', value: '飲食店' },
  { code: 'takeaway_and_delivery', value: '持ち帰り・配達飲食サービス業' },
  { code: 'medical_services', value: '医療業' },
  { code: 'public_health', value: '保健衛生' },
  {
    code: 'social_insurance_and_welfare',
    value: '社会保険・社会福祉・介護事業',
  },
  { code: 'universities', value: '大学' },
  { code: 'vocational_schools', value: '専修学校' },
  { code: 'education_and_training_services', value: '教育・研修サービス' },
  { code: 'school_education', value: '学校教育' },
  { code: 'learning_support', value: '学習支援' },
  { code: 'academic_and_research_institutions', value: '学術・開発研究機関' },
  { code: 'advertising_and_marketing', value: '広告・マーケ・PR' },
  { code: 'publishing_and_printing', value: '出版・印刷' },
  { code: 'art_illustration_and_photography', value: 'アート・イラスト・写真' },
  { code: 'entertainment', value: '芸能・エンタメ' },
  { code: 'real_estate_services', value: '不動産サービス' },
  { code: 'construction_services', value: '建設サービス' },
  { code: 'staffing_services', value: '人材サービス' },
  { code: 'travel', value: '旅行' },
  { code: 'leisure_and_amusement', value: 'レジャー・アミューズメント施設' },
  { code: 'fitness_and_spas', value: 'フィットネス・スパ・浴場' },
  { code: 'beauty_and_relaxation', value: '美容・エステ・リラクゼーション' },
  { code: 'cleaning_and_maintenance', value: 'クリーニング・メンテナンス' },
  { code: 'ceremonial_services', value: '冠婚葬祭' },
  { code: 'security', value: '警備' },
  { code: 'automobile_and_machinery_repair', value: '自動車整備・機械等修理' },
  { code: 'call_centers', value: 'コールセンター・業務請負' },
  { code: 'goods_rental', value: '物品レンタル' },
  { code: 'environmental_services', value: '環境サービス・廃棄物処理' },
  { code: 'research_and_consulting', value: 'リサーチ・コンサルティング' },
  { code: 'video_musics', value: '映像・音楽' },
  { code: 'news_services', value: '新聞・通信社' },
  { code: 'regional_revitalization', value: '地方創生' },
  { code: 'it_services', value: 'ITサービス' },
  { code: 'other_services', value: 'その他サービス' },
  { code: 'prefectures', value: '都道府県' },
  { code: 'municipalities', value: '市町村' },
  { code: 'special_wards', value: '特別区（23区）' },
  { code: 'administrative_organizations', value: '行政機関' },
  { code: 'political_parties', value: '政党' },
  {
    code: 'independent_administrative_agencies',
    value: '独立行政・公益・社団・財団法人',
  },
  { code: 'npos_and_ngos', value: 'NPO・NGO' },
  { code: 'citizen_groups', value: '市民団体' },
  { code: 'associations_and_unions', value: '協会・組合' },
  { code: 'cooperatives', value: '協同組合' },
  { code: 'baseball', value: '野球' },
  { code: 'soccer_football', value: 'サッカー' },
  { code: 'rugby', value: 'ラグビー' },
  { code: 'basketball', value: 'バスケット' },
  { code: 'volleyball', value: 'バレーボール' },
  { code: 'table_tennis', value: '卓球' },
  { code: 'ice_hockey', value: 'アイスホッケー' },
  { code: 'curling', value: 'カーリング' },
  { code: 'american_football', value: 'アメリカンフットボール' },
  { code: 'sumo_wrestling', value: '相撲' },
  { code: 'horse_racing', value: '競馬' },
  { code: 'asia', value: 'アジア' },
  { code: 'middle_east', value: '中東' },
  { code: 'north_america', value: '北米' },
  { code: 'central_and_south_america', value: '中南米' },
  { code: 'europe', value: 'ヨーロッパ' },
  { code: 'oceania', value: 'オセアニア' },
  { code: 'africa', value: 'アフリカ' },
  { code: 'united_nations_agencies', value: '国連諸機関' },
  { code: 'sports', value: 'スポーツ' },
]

export const INDUSTRY_CODE_RELATION: { [key: string]: string[] } = {
  fishery_agriculture: ['agriculture', 'forestry', 'fisheries'],
  mining: ['mining_and_quarrying'],
  construction: [
    'construction',
    'construction_consulting',
    'plant_engineering',
  ],
  manufacturing: [
    'food_products',
    'textiles',
    'pulp_and_paper',
    'chemicals',
    'pharmaceuticals',
    'rubber_products',
    'steel',
    'non_ferrous_metals',
    'metal_products',
    'machinery',
    'electrical_equipment',
    'transportation_equipment',
    'precision_instruments',
    'glass_and_ceramic_products',
    'petroleum_and_coal_products',
    'other_products',
  ],
  electricity_gas: ['electricity', 'gas', 'heat_supply', 'water_supply'],
  transportation: [
    'railway_services',
    'road_passenger_transport',
    'road_freight_transport',
    'water_transport',
    'air_transport',
    'warehousing',
    'transportation_support_services',
    'postal_services',
  ],
  information_communication: [
    'information_services',
    'telecommunications',
    'broadcasting',
    'gaming',
    'system_integrators',
    'data_centers',
  ],
  commerce: [
    'wholesale',
    'department_stores',
    'supermarkets',
    'home_centers',
    'consumer_electronics',
    'fashion_and_apparel',
    'automotive_and_transportation',
    'music_books_and_interior_goods',
    'drugstores',
    'optical_goods',
    'sporting_goods',
    'energy',
    'automobiles_and_auto_parts',
    'consumer_cooperatives',
    'electronics_and_semiconductors',
    'second_hand_and_reuse',
    'other_retail_and_distribution',
  ],
  finance_insurance: [
    'investment_funds_and_related_services',
    'securities_and_futures',
    'banks',
    'credit_unions',
    'credit_associations',
    'agricultural_finance',
    'insurance',
    'credit_and_financing',
    'mergers_and_acquisitions',
    'other_financial_services',
  ],
  real_estate: [
    'real_estate_transactions',
    'real_estate_leasing_and_management',
    'goods_leasing',
  ],
  food_accommodation: [
    'accommodation_services',
    'restaurants',
    'takeaway_and_delivery',
  ],
  medical_welfare: [
    'medical_services',
    'public_health',
    'social_insurance_and_welfare',
  ],
  education: [
    'universities',
    'vocational_schools',
    'education_and_training_services',
    'school_education',
    'learning_support',
    'academic_and_research_institutions',
  ],
  service: [
    'advertising_and_marketing',
    'publishing_and_printing',
    'art_illustration_and_photography',
    'entertainment',
    'real_estate_services',
    'construction_services',
    'staffing_services',
    'travel',
    'leisure_and_amusement',
    'fitness_and_spas',
    'beauty_and_relaxation',
    'cleaning_and_maintenance',
    'ceremonial_services',
    'security',
    'automobile_and_machinery_repair',
    'call_centers',
    'goods_rental',
    'environmental_services',
    'research_and_consulting',
    'video_musics',
    'news_services',
    'regional_revitalization',
    'it_services',
    'other_services',
  ],
  government: [
    'prefectures',
    'municipalities',
    'special_wards',
    'administrative_organizations',
  ],
  organization: [
    'political_parties',
    'independent_administrative_agencies',
    'npos_and_ngos',
    'citizen_groups',
    'associations_and_unions',
    'cooperatives',
  ],
  sports: [
    'baseball',
    'soccer_football',
    'rugby',
    'basketball',
    'volleyball',
    'table_tennis',
    'ice_hockey',
    'curling',
    'american_football',
    'sumo_wrestling',
    'horse_racing',
  ],
  country: [
    'asia',
    'middle_east',
    'north_america',
    'central_and_south_america',
    'europe',
    'oceania',
    'africa',
  ],
  world: ['united_nations_agencies', 'sports'],
}

export const BUSINESS_PARENT_CODE = [
  { code: 'technology', value: 'テクノロジー' },
  { code: 'application', value: 'アプリ' },
  { code: 'entertaiment', value: 'エンタメ' },
  { code: 'vehicle', value: '乗り物' },
  { code: 'shopping', value: 'ショッピング' },
  { code: 'beauty', value: 'ビューティー' },
  { code: 'fashion', value: 'ファッション' },
  { code: 'food', value: '食' },
  { code: 'lifestyle', value: 'ライフスタイル' },
  { code: 'learning', value: '学び' },
  { code: 'business', value: 'ビジネス' },
  { code: 'medical', value: '医療' },
  { code: 'building', value: '建物' },
  { code: 'leisure', value: 'レジャー' },
  { code: 'sport', value: 'スポーツ・アウトドア' },
  { code: 'npo', value: '非営利法人' },
  { code: 'international', value: '国際' },
  { code: 'work', value: 'ワークスタイル' },
]

export const BUSINESS_CHILD_CODE = [
  { code: 'pc_device', value: 'PC・デバイス' },
  { code: 'smartphone', value: 'スマートフォン' },
  { code: 'network', value: '回線' },
  { code: 'camera', value: 'カメラ' },
  { code: 'home_appliances', value: '白物・健康・美容家電' },
  { code: 'cloud_computing', value: 'クラウドコンピューティング' },
  { code: 'security', value: 'セキュリティ' },
  { code: 'web_development', value: 'Web・システム開発' },
  { code: 'precision_instruments', value: '精密機器' },
  { code: 'online_services', value: 'ネットサービス' },
  { code: 'smartphone_app', value: 'スマートフォンアプリ' },
  { code: 'smartphone_game', value: 'スマートフォンゲーム' },
  { code: 'solutions', value: 'ソリューション' },
  { code: 'entertainment_music', value: '芸能・音楽・テレビ・CM' },
  { code: 'movies_plays_dvd', value: '映画・演劇・DVD' },
  { code: 'publishing_art_culture', value: '出版・アート・カルチャー' },
  { code: 'games_toys', value: 'ゲーム・おもちゃ' },
  { code: 'automotive_motorcycle', value: '自動車・バイク' },
  { code: 'transportation', value: '交通' },
  { code: 'online_shopping_delivery', value: 'ネットショップ・通販' },
  { code: 'department_store', value: '百貨店・スーパー・コンビニ・ストア' },
  { code: 'skincare_cosmetics', value: 'スキンケア・化粧品・ヘア用品' },
  { code: 'health_supplements', value: 'ダイエット・健康食品・サプリメント' },
  { code: 'fitness_healthcare', value: 'フィットネス' },
  { code: 'fashion_women', value: 'レディースファッション' },
  { code: 'fashion_men', value: 'メンズファッション' },
  { code: 'shoes_bags', value: 'シューズ・バッグ' },
  { code: 'jewelry_accessories', value: 'ジュエリー・アクセサリー' },
  { code: 'kids_baby_maternity', value: 'キッズ・ベビー・マタニティ' },
  { code: 'food_sweets_snacks', value: 'フード・スイーツ・お菓子' },
  { code: 'beverages', value: '飲みもの' },
  { code: 'restaurants', value: '飲食店' },
  { code: 'home_delivery', value: '宅配' },
  { code: 'fair_trade', value: 'フェアトレード' },
  { code: 'skincare_cosmetics', value: 'スキンケア・化粧品・ヘア用品' },
  {
    code: 'diet_health_supplements',
    value: 'ダイエット・健康食品・サプリメント',
  },
  { code: 'fitness_healthcare', value: 'フィットネス・ヘルスケア' },
  { code: 'interior_diy_miscellaneous', value: 'インテリア・DIY・雑貨' },
  { code: 'pets_pet_supplies', value: 'ペット・ペット用品' },
  { code: 'gifts_flowers', value: 'ギフト・花' },
  { code: 'romance_wedding', value: '恋愛・ウェディング' },
  { code: 'childbirth_childcare', value: '出産・育児' },
  { code: 'end_of_life_funeral', value: '終活・葬儀' },
  { code: 'job_hunting', value: '就職・転職・人材派遣・アルバイト' },
  { code: 'qualification_study', value: '資格・留学・語学' },
  { code: 'school_university', value: '学校・大学' },
  { code: 'cram_school', value: '学習塾・予備校・通信教育' },
  { code: 'childhood_education', value: '保育・幼児教育' },
  { code: 'management_consulting', value: '経営・コンサルティング' },
  { code: 'advertising', value: '広告・宣伝・PR' },
  { code: 'marketing', value: 'マーケティング' },
  { code: 'sales', value: '営業' },
  { code: 'finance', value: '金融' },
  { code: 'distribution', value: '流通' },
  { code: 'transportation_shipping', value: '運輸・輸送' },
  { code: 'materials_chemistry', value: '素材・化学・エネルギー' },
  { code: 'finance_accounting', value: '財務・経理' },
  { code: 'legal', value: '法務・特許・知的財産' },
  { code: 'startup', value: 'スタートアップ' },
  { code: 'medical_hospital', value: '医療・病院' },
  { code: 'pharmaceuticals', value: '医薬・製薬' },
  { code: 'welfare', value: '福祉・介護・リハビリ' },
  { code: 'housing_apartments', value: '住宅・マンション' },
  { code: 'commercial_facilities', value: '商業施設・オフィスビル' },
  { code: 'architecture_space_design', value: '建築・空間デザイン' },
  { code: 'construction', value: '建設・土木' },
  { code: 'hotels_inns', value: 'ホテル・旅館' },
  { code: 'travel_tourism', value: '旅行・観光' },
  { code: 'amusement_parks', value: 'テーマパーク・遊園地' },
  { code: 'baseball', value: '野球' },
  { code: 'soccer', value: 'サッカー' },
  { code: 'basketball', value: 'バスケット' },
  { code: 'volleyball', value: 'バレー' },
  { code: 'rugby', value: 'ラグビー' },
  { code: 'track_and_field', value: '陸上' },
  { code: 'swimming', value: '水泳' },
  { code: 'judo', value: '柔道' },
  { code: 'kendo', value: '剣道' },
  { code: 'martial_arts', value: '武道・格闘技' },
  { code: 'gymnastics', value: '体操・新体操' },
  { code: 'table_tennis', value: '卓球' },
  { code: 'tennis', value: 'テニス' },
  { code: 'badminton', value: 'バトミントン' },
  { code: 'golf', value: 'ゴルフ' },
  { code: 'skiing', value: 'スキー・スノボード・スケート' },
  { code: 'extreme_sports', value: 'エクストリームスポーツ' },
  { code: 'mountain_climbing', value: '登山・クライミング' },
  { code: 'walking', value: 'ウォーキング' },
  { code: 'running', value: 'ランニング' },
  { code: 'cycling', value: 'サイクリング' },
  { code: 'fishing', value: '釣り' },
  { code: 'camping', value: 'キャンプ' },
  { code: 'canoeing_SUP', value: 'カヌー・SUP' },
  { code: 'marine_air_sports', value: 'マリン・スカイスポーツ' },
  { code: 'nonprofit_organization', value: 'NPO法人' },
  { code: 'association', value: '一般社団法人' },
  { code: 'foundation', value: '一般財団法人' },
  { code: 'asia_oceania', value: 'アジア・オセアニア' },
  { code: 'europe', value: 'ヨーロッパ' },
  { code: 'north_america', value: '北米' },
  { code: 'latin_america', value: '中南米' },
  { code: 'africa', value: 'アフリカ' },
  { code: 'remote_work', value: 'リモートワーク' },
  { code: 'workation', value: 'ワーケーション' },
  { code: 'side_job', value: '副業・兼業' },
  { code: 'volunteer', value: 'ボランティア' },
  { code: 'agriculture', value: '農林・水産' },
]

export const BUSINESS_CODE_RELATION: { [key: string]: string[] } = {
  technology: [
    'pc_device',
    'smartphone',
    'network',
    'camera',
    'home_appliances',
    'cloud_computing',
    'security',
    'web_development',
    'precision_instruments',
  ],
  application: [
    'online_services',
    'smartphone_app',
    'smartphone_game',
    'solutions',
  ],
  entertaiment: [
    'entertainment_music',
    'movies_plays_dvd',
    'publishing_art_culture',
    'games_toys',
  ],
  vehicle: ['automotive_motorcycle', 'transportation'],
  shopping: ['online_shopping_delivery', 'department_store'],
  beauty: ['skincare_cosmetics', 'health_supplements', 'fitness_healthcare'],
  fashion: [
    'fashion_women',
    'fashion_men',
    'shoes_bags',
    'jewelry_accessories',
    'kids_baby_maternity',
  ],
  food: [
    'food_sweets_snacks',
    'beverages',
    'restaurants',
    'home_delivery',
    'fair_trade',
  ],
  lifestyle: [
    'skincare_cosmetics',
    'diet_health_supplements',
    'fitness_healthcare',
    'interior_diy_miscellaneous',
    'pets_pet_supplies',
    'gifts_flowers',
    'romance_wedding',
    'childbirth_childcare',
    'end_of_life_funeral',
  ],
  learning: [
    'job_hunting',
    'qualification_study',
    'school_university',
    'cram_school',
    'childhood_education',
  ],
  business: [
    'management_consulting',
    'advertising',
    'marketing',
    'sales',
    'finance',
    'distribution',
    'transportation_shipping',
    'materials_chemistry',
    'finance_accounting',
    'legal',
    'startup',
  ],
  medical: ['medical_hospital', 'pharmaceuticals', 'welfare'],
  building: [
    'housing_apartments',
    'commercial_facilities',
    'architecture_space_design',
    'construction',
  ],
  leisure: ['hotels_inns', 'travel_tourism', 'amusement_parks'],
  sport: [
    'baseball',
    'soccer',
    'basketball',
    'volleyball',
    'rugby',
    'track_and_field',
    'swimming',
    'judo',
    'kendo',
    'martial_arts',
    'gymnastics',
    'table_tennis',
    'tennis',
    'badminton',
    'golf',
    'skiing',
    'extreme_sports',
    'mountain_climbing',
    'walking',
    'running',
    'cycling',
    'fishing',
    'camping',
    'canoeing_SUP',
    'marine_air_sports',
  ],
  npo: ['nonprofit_organization', 'association', 'foundation'],
  international: [
    'asia_oceania',
    'europe',
    'north_america',
    'latin_america',
    'africa',
  ],
  work: ['remote_work', 'workation', 'side_job', 'volunteer', 'agriculture'],
}

// 以下ランキング
export const RANKING_PERIOD_CODE = [
  { code: RankingPeriod.All, label: '総合' },
  { code: RankingPeriod.Yearly, label: '年間' },
  { code: RankingPeriod.Weekly, label: '週間' },
]
export const RANKING_KUCHIKOMI_STAT_CODE = [
  { code: RankingKuchikomiStat.GoodCount, label: 'いいね数' },
  { code: RankingKuchikomiStat.BookmarkCount, label: 'ブックマーク数' },
  { code: RankingKuchikomiStat.CommentCount, label: 'コメント数' },
]
export const RANKING_ENTERPRISE_STAT_CODE = [
  { code: RankingEnterpriseStat.Evaluation, label: '評価' },
  { code: RankingEnterpriseStat.KuchikomiCount, label: 'クチコミ数' },
  { code: RankingEnterpriseStat.FollwerCount, label: 'フォロワー数' },
]
export const RANKING_USER_STAT_CODE = [
  { code: RankingUserStat.SendPointCount, label: 'ポイント贈呈数' },
  { code: RankingUserStat.KuchikomiCount, label: 'クチコミ数' },
  { code: RankingUserStat.FollwerCount, label: 'フォロワー数' },
]

export const BOOKMARK_STAT_CODE = [
  { code: BookmarkStat.NewArrival, label: '新着順' },
  { code: BookmarkStat.GoodCount, label: 'いいね数ランキング' },
]
