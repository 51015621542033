import { useCallback, useState } from 'react'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import { useSnackBar } from '../../../providers/snack_bar_provider'
import { EnterpriseMaterial } from '../../../clients/api_client'

type Handler = {
  load: (enterpriseId: string) => Promise<void>
}

const useEnterpriseMaterial = (): [
  EnterpriseMaterial | null,
  boolean,
  Handler,
] => {
  const apiClient = getAppClient()
  const { showSnackBar } = useSnackBar()
  const [isLoading, setLoading] = useState(false)
  const [enterpriseMaterial, setEnterpriseMaterial] =
    useState<EnterpriseMaterial | null>(null)

  const load = useCallback(async (enterpriseId: string) => {
    setLoading(true)
    await apiClient.enterprises
      .materialDetail(enterpriseId)
      .then((res) => {
        const data = res.data
        setEnterpriseMaterial(data)
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
        return
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return [enterpriseMaterial, isLoading, { load: load }]
}

export default useEnterpriseMaterial
